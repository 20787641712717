import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import DataTable from "react-data-table-component";

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb, Modal, Tabs, Tab } from 'react-bootstrap'

import { formatToCNPJ, isCNPJ, formatToPhone } from 'brazilian-values'

function TelaPropostas() {

    const { conexao, loading, limpaCNPJ, setLoading } = useContext(GlobalContext)

    const [propostas, setPropostas] = useState([])
    const [itensPropostas, setItensPropostas] = useState([])

    const [propostaSelecionada, setPropostaSelecionada] = useState({})
    const [itemAtual, setItemAtual] = useState({})


    const [modalProposta, setModalProposta] = useState(false)
    const [modoEdicao, setModoEdicao] = useState(false)

    const Navigate = useNavigate()

    useEffect(() => {
        GetPropostas()
    }, [])

    function GetPropostas() {

        setLoading(true)

        const url = conexao.endereco + '/propostas/listar'

        let body = {
            "empresa": localStorage.getItem("Empresa")
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setPropostas(resposta)
            }
        }
    }

    function GetItensPropostas(proposta) {

        setLoading(true)

        const url = conexao.endereco + '/propostas/itens/listar'

        let body = {
            "proposta": proposta
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setItensPropostas(resposta)
            } else {
                setItensPropostas([])
            }
        }
    }

    function AtualizarProposta() {

        setLoading(true)

        const url = conexao.endereco + '/propostas/atualizar'

        let body = {
            "proposta": propostaSelecionada.prop_id,
            "descricao": propostaSelecionada.prop_descricao,
            "texto": propostaSelecionada.prop_texto,
            "cliente": propostaSelecionada.prop_cliente,
            "email": propostaSelecionada.prop_email,
            "whatsapp": propostaSelecionada.prop_whatsapp,
            "status": propostaSelecionada.prop_status
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                GetPropostas()
                setLoading(false)
            }
        }
    }

    function IncluirProposta() {

        setLoading(true)

        const url = conexao.endereco + '/propostas/incluir'

        let body = {
            "empresa": localStorage.getItem("Empresa"),
            "descricao": propostaSelecionada.prop_descricao,
            "texto": propostaSelecionada.prop_texto,
            "cliente": propostaSelecionada.prop_cliente,
            "email": propostaSelecionada.prop_email,
            "whatsapp": propostaSelecionada.prop_whatsapp,
            "status": propostaSelecionada.prop_status
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 201) {
                let resposta = JSON.parse(this.response)
                GetPropostas()
                setLoading(false)
            }
        }
    }

    function IncluirItemProposta() {

        setLoading(true)

        const url = conexao.endereco + '/propostas/itens/incluir'

        let body = {
            "proposta": propostaSelecionada?.prop_id,
            "item": itemAtual.item,
            "desconto": itemAtual.desconto,
            "valor": itemAtual.valor
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 201) {
                let resposta = JSON.parse(this.response)
                GetItensPropostas(propostaSelecionada?.prop_id)
                setItemAtual({ item: '', valor: '', desconto: '0' })
                setLoading(false)
            }
        }
    }

    function ExcluirProposta() {

        setLoading(true)

        const url = conexao.endereco + '/propostas/excluir'

        let body = {
            "proposta": propostaSelecionada.prop_id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                GetPropostas()
                setLoading(false)
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Data',
            selector: row => row.data_formatada,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '120px',
        },
        {
            name: 'Descrição',
            selector: row => row.prop_descricao,
            sortable: true,
            // minWidth: '120px',
            // maxWidth: '120px',
        },
        {
            name: 'Cliente',
            selector: row => row.prop_cliente,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Status',
            selector: row => row.prop_status,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '380px',
        }
    ];

    const colunasItens = [
        {
            name: 'Descrição do Item',
            selector: row => row.item_descricao,
            sortable: true,
            // minWidth: '120px',
            // maxWidth: '120px',
        },
        {
            name: 'Valor',
            selector: row => Number(row.item_valor),
            format: row => Number(row?.item_valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '150px',
            right: 'true'
        },
        {
            name: 'Desconto',
            selector: row => Number(row.item_desconto),
            format: row => Number(row?.item_desconto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '150px',
            right: 'true'
        },
        {
            name: 'Valor Final',
            selector: row => Number(row?.item_valor) - Number(row?.item_desconto),
            format: row => Number(Number(row?.item_valor) - Number(row?.item_desconto)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '150px',
            right: 'true'
        }
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Propostas Comerciais</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        setModoEdicao(false)
                                        setPropostaSelecionada({})
                                        setItemAtual({ item: '', valor: '', desconto: '0' })
                                        setModalProposta(true)
                                    }}>Incluir</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    {/* <Card.Header className="dashboard-cartao"><Row><Col lg={8} style={{display: 'flex', alignItems: 'center'}}>Cadastro de Produtos</Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Descrição" /></Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Departamento" /></Col></Row></Card.Header> */}
                                    <Card.Header className="dashboard-cartao">Propostas Comerciais</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={propostas}
                                            onRowClicked={(row, index) => {
                                                setModoEdicao(true)
                                                setPropostaSelecionada(row)
                                                setItemAtual({ item: '', valor: '', desconto: '0' })
                                                GetItensPropostas(row.prop_id)
                                                setModalProposta(true)
                                            }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>

            <Modal
                show={modalProposta}
                onHide={() => { setModalProposta(false) }}
                backdrop="static"
                keyboard={false}
                dialogClassName={"modal-70"}
                centered
            >

                <Modal.Body>

                    <Tabs
                        defaultActiveKey="geral"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="geral" title="Geral">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_descricao: e.target.value }) }} value={propostaSelecionada?.prop_descricao} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Cliente</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_cliente: e.target.value }) }} value={propostaSelecionada?.prop_cliente} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control style={{ textTransform: "lowercase" }} size="sm" type="email" onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_email: e.target.value }) }} value={propostaSelecionada?.prop_email} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>WhatsApp</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_whatsapp: e.target.value }) }} value={propostaSelecionada.prop_whatsapp ? formatToPhone(propostaSelecionada?.prop_whatsapp) : null} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select size="sm" onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_status: e.target.value }) }} value={propostaSelecionada?.prop_status}>
                                            <option>Selecione</option>
                                            <option value="PENDENTE_ENVIO">Pendente Envio</option>
                                            <option value="ENVIADA">Enviada</option>
                                            {/* <option value="REAL">Lucro Real</option>
                                    <option value="PRESUMIDO">Lucro Presumido</option> */}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Descrição da Proposta</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" as="textarea" rows={12} onChange={(e) => { setPropostaSelecionada({ ...propostaSelecionada, prop_texto: e.target.value }) }} value={propostaSelecionada?.prop_texto} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="itens" title="Itens">
                            <Row>
                                <Col lg={5}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Descrição do Item</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setItemAtual({ ...itemAtual, item: e.target.value }) }} value={itemAtual?.item} />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Valor</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="number" onChange={(e) => { setItemAtual({ ...itemAtual, valor: e.target.value }) }} value={itemAtual?.valor} />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Desconto</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="number" onChange={(e) => { setItemAtual({ ...itemAtual, desconto: e.target.value }) }} value={itemAtual?.desconto} />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Valor Final</Form.Label>
                                        <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="number" value={Number(itemAtual?.valor) - Number(itemAtual?.desconto)} disabled />
                                    </Form.Group>
                                </Col>
                                <Col lg={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button variant="success" onClick={() => {
                                        if (itemAtual?.item && itemAtual?.valor && itemAtual?.desconto) {
                                            IncluirItemProposta()
                                        } else {
                                            alert('Campos Inválidos')
                                        }

                                    }}>Incluir</Button>
                                </Col>
                            </Row>
                            <Row>
                                <DataTable
                                    columns={colunasItens}
                                    data={itensPropostas}
                                    striped={true}
                                    highlightOnHover={true}
                                    responsive={true}
                                    pointerOnHover={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                            </Row>
                        </Tab>
                        <Tab eventKey="condicoes" title="Condições">
                            <h1>Tab 3</h1>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light"
                        onClick={() => { setModalProposta(false) }}
                    >
                        Fechar
                    </Button>
                    {modoEdicao &&
                        <Button variant="danger" onClick={() => {

                            ExcluirProposta()
                            setModalProposta(false)

                        }}>Excluir</Button>
                    }
                    {modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            // alert(limpaCNPJ(propostaSelecionada.loja_cnpj))
                            AtualizarProposta()
                            setModalProposta(false)

                        }}>Salvar</Button>
                    }
                    {!modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            // alert(limpaCNPJ(propostaSelecionada.loja_cnpj))
                            IncluirProposta()
                            setModalProposta(false)

                        }}>Cadastrar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TelaPropostas
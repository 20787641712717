import logo2 from './../assets/logo.png'
import React, { useState } from "react";
import { MdDashboard, MdList, MdShoppingBasket, MdSettings, MdOutlineHelp, MdAreaChart, MdAppRegistration, MdSyncAlt, MdOutlineAnalytics } from 'react-icons/md'
import { BsCaretDownFill, BsCaretUpFill, BsDot } from 'react-icons/bs'   
import { SiSalesforce } from 'react-icons/si'   

import { useNavigate } from 'react-router-dom'

function Sidebar() {

    const Navigate = useNavigate();

    function AbreMenu(item) {
        if (item == 'cadastros') {
            setMenuCadastros(!menuCadastros)
            setMenuVendas(false)
            setMenuCompras(false)
            setMenuEstoques(false)
            setMenuFinanceiro(false)
            setMenuContabilidade(false)
            setMenuCRM(false)
        }
        if (item == 'vendas') {
            setMenuVendas(!menuVendas)
            setMenuCadastros(false)
            setMenuCompras(false)
            setMenuEstoques(false)
            setMenuFinanceiro(false)
            setMenuContabilidade(false)
            setMenuCRM(false)
        }
        if (item == 'compras') {
            setMenuCompras(!menuCompras)
            setMenuCadastros(false)
            setMenuVendas(false)
            setMenuEstoques(false)
            setMenuFinanceiro(false)
            setMenuContabilidade(false)
            setMenuCRM(false)
        }
        if (item == 'estoques') {
            setMenuEstoques(!menuEstoques)
            setMenuCompras(false)
            setMenuCadastros(false)
            setMenuVendas(false)
            setMenuFinanceiro(false)
            setMenuContabilidade(false)
            setMenuCRM(false)
        }
        if (item == 'financeiro') {
            setMenuFinanceiro(!menuFinanceiro)
            setMenuCompras(false)
            setMenuCadastros(false)
            setMenuVendas(false)
            setMenuEstoques(false)
            setMenuContabilidade(false)
            setMenuCRM(false)
        }
        if (item == 'contabilidade') {
            setMenuContabilidade(!menuContabilidade)
            setMenuFinanceiro(false)
            setMenuCompras(false)
            setMenuCadastros(false)
            setMenuVendas(false)
            setMenuEstoques(false)
            setMenuCRM(false)
        }
        if (item == 'crm') {
            setMenuCRM(!menuCRM)
            setMenuContabilidade(!menuContabilidade)
            setMenuFinanceiro(false)
            setMenuCompras(false)
            setMenuCadastros(false)
            setMenuVendas(false)
            setMenuEstoques(false)
        }
    }

    const [menuCadastros, setMenuCadastros] = useState(false)
    const [menuVendas, setMenuVendas] = useState(false)
    const [menuCompras, setMenuCompras] = useState(false)
    const [menuEstoques, setMenuEstoques] = useState(false)
    const [menuFinanceiro, setMenuFinanceiro] = useState(false)
    const [menuContabilidade, setMenuContabilidade] = useState(false) 
    const [menuCRM, setMenuCRM] = useState(false) 

    return (

        <div className='app'>
            <div className='sidebar'>
                <div className='sidebar-topo'>
                    <img
                        className='sidebar-logo'
                        src={logo2}
                        width="50%"
                        alt='Logo Sistema'
                    />
                </div>
                <div className='sidebar-content'>
                    <ul>
                        <li
                            onClick={() => { Navigate('/dashboard') }}>
                            <div className='menu-icone'>
                                <MdDashboard size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Dashboard
                            </div>
                        </li>
                        <li
                            onClick={() => { AbreMenu('cadastros') }}>
                            <div className='menu-icone'>
                                <MdList size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Cadastros
                                {!menuCadastros ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuCadastros &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Produtos
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Clientes
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Fornecedores
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('estoques') }}>
                            <div className='menu-icone'>
                                <MdSyncAlt size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Estoques
                                {!menuEstoques ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuEstoques &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/estoques/entradas') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Entradas
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/estoques/saidas') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Saídas
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Remanejamento
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Auditoria / Contagem
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('compras') }}>
                            <div className='menu-icone'>
                                <MdShoppingBasket size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Compras
                                {!menuCompras ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuCompras &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Pedido de Compra
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Conferência
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Recebimento
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('vendas') }}>
                            <div className='menu-icone'>
                                <MdOutlineAnalytics size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Vendas
                                {!menuVendas ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuVendas &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/propostas-comerciais') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Proposta Comercial
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Pedido de Venda
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        PDV
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('crm') }}>
                            <div className='menu-icone'>
                                <SiSalesforce size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                CRM
                                {!menuCRM ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuCRM &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Leads
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Formulários
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Negociações
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('financeiro') }}>
                            <div className='menu-icone'>
                                <MdAreaChart size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Financeiro
                                {!menuFinanceiro ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuFinanceiro &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Contas / Bancos
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Contas a Pagar
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Contas a Receber
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { AbreMenu('contabilidade') }}>
                            <div className='menu-icone'>
                                <MdAppRegistration size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Contabilidade
                                {!menuContabilidade ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuContabilidade &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Impostos
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Demonstrações
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Integração
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { Navigate('/configuracoes') }}>
                            <div className='menu-icone'>
                                <MdSettings size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Configurações
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/ajuda') }}>
                            <div className='menu-icone'>
                                <MdOutlineHelp size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Obter Ajuda
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Sidebar
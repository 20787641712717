import React, { useState, useEffect, useContext } from "react"
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from "../../contexts/global";
import './style.css';
import axios from "axios";


import { formatToCPFOrCNPJ, isCNPJ } from 'brazilian-values'
import Logo from '../../assets/logo.png'


function TelaPrimeiroAcesso() {

    const [cnpj, setCNPJ] = useState('')
    const [nome, setNome] = useState('')
    const [razao, setRazao] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')


    const [textoAcessar, setTextoAcessar] = useState('Acessar')

    const { conexao, timeSelecionado, periodo, modalPeriodo, setModalPeriodo, setPeriodo, loading, setLoading, apenasNumeros } = useContext(GlobalContext)

    const Navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            setTextoAcessar('Aguarde...')
        } else {
            setTextoAcessar('Cadastrar')
        }
    }, [loading])

    useEffect(() => {
        localStorage.removeItem('Usuário Logado')
    }, [])

    function GetDadosCNPJ(cnpj) {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const cnpjnumeros = String(apenasNumeros(cnpj))

        if (isCNPJ(cnpj)) {
            axios.get('https://publica.cnpj.ws/cnpj/' + cnpjnumeros, config)
                .then(function (response) {
                    if (response.status == 200) {
                        const dados = response.data

                        setRazao(dados.razao_social)
                        setNome(dados.estabelecimento.nome_fantasia)


                    }
                })
                .catch(function (error) {
                    console.log('Deu Erro')
                    console.log(error)
                });
        }



    }


    function CriarPrimeiroAcesso() {

        setLoading(true)

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios.post(conexao.endereco + '/primeiroacesso', {
            "razao": razao.toLowerCase(),
            "fantasia": nome.toLowerCase(),
            "cnpj": apenasNumeros(cnpj),
            "email": email,
            "senha": senha
        }, config)
            .then(function (response) {
                setLoading(false)
                if (response.status == 201) {
                    localStorage.setItem('email', email)
                    alert(response.data.message)
                    Navigate('/')
                } else {
                    alert("Falha ao Criar a Empresa")
                }

            })
            .catch(function (error) {
                setLoading(false)
                console.log('Deu Erro')
            });


    }

    return (
        <div className="main-login">

            <div className="left-login">
                <img
                    className="left-login-image"
                    src={Logo}
                    alt={'Logo Gestor Atendimentos'}
                    loading={"lazy"}
                ></img>
            </div>
            <div className="right-login">
                <div className="card-login">
                    <h1>Primeiro Acesso</h1>
                    <div className="textfield">
                        <label for="cnpj">CNPJ</label>
                        <input type={"text"} value={formatToCPFOrCNPJ(cnpj)} onBlur={() => {
                            GetDadosCNPJ(cnpj)
                        }} onChange={(e) => { setCNPJ(e.target.value) }}></input>
                    </div>
                    <div className="textfield">
                        <label for="razao">Razão Social</label>
                        <input type={"text"} value={razao} onChange={(e) => { setRazao(e.target.value) }}></input>
                    </div>
                    <div className="textfield">
                        <label for="nome">Nome Fantasia</label>
                        <input type={"text"} value={nome} onChange={(e) => { setNome(e.target.value) }}></input>
                    </div>
                    <div className="textfield">
                        <label for="nome">Endereço de E-mail</label>
                        <input type={"mail"} value={email} onChange={(e) => { setEmail(e.target.value) }}></input>
                    </div>
                    <div className="textfield">
                        <label for="nome">Senha de Acesso</label>
                        <input type={"password"} value={senha} onChange={(e) => { setSenha(e.target.value) }}></input>
                    </div>
                    <button className="btn-login" onClick={() => {
                        CriarPrimeiroAcesso()
                    }}>{textoAcessar}</button>
                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ color: 'gray', cursor: "pointer" }} onClick={() => {
                            Navigate('/')
                        }}>Voltar a Tela de Login</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TelaPrimeiroAcesso
import React from "react";
import Sidebar from "../../../components/sidebar";
import TopBar from "../../../components/topbar";

import { Col, Row, Card, Breadcrumb } from 'react-bootstrap'

import { MdOutlineDocumentScanner, MdKeyboard } from 'react-icons/md'

function EstoqueMenuSaidas() {

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Estoques</Breadcrumb.Item>
                                <Breadcrumb.Item active>Saídas</Breadcrumb.Item>
                            </Breadcrumb>

                        </Row>
                        <Row>
                            <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { alert('teste') }}>
                                <Card className="card-interno">
                                    <Card.Body>
                                        <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <MdKeyboard color="#436996" size="36" />
                                        </Card.Header>
                                        <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>Lançamento Manual</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { alert('teste') }}>
                                <Card className="card-interno">
                                    <Card.Body>
                                        <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <MdOutlineDocumentScanner color="#436996" size="36" />
                                        </Card.Header>
                                        <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>Importar NFe</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default EstoqueMenuSaidas
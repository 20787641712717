import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './contexts/global'

import Dashboard from './pages/Dashboard';
import TelaLogin from './pages/Login';
import TelaPrimeiroAcesso from './pages/PrimeiroAcesso';
import CadastroProdutos from './pages/Cadastros/produtos'
import InclusaoProduto from './pages/Cadastros/produto-inclusao';
import CadastroDepartamentos from './pages/Cadastros/departamentos'
import Analytics from './pages/Analytics';
import Integracoes from './pages/Integracoes'
import Configuracoes from './pages/Configuracoes'
import Ajuda from './pages/Ajuda'
import Alertas from './pages/Alertas'
import TelaMembros from './pages/Membros';
import MinhasLojas from './pages/MinhasLojas';
import TelaPropostas from './pages/Propostas';

import EstoqueMenuEntradas from './pages/Estoques/Entradas/menu'
import EstoqueMenuEntradasManual from './pages/Estoques/Entradas/manual';
import EstoqueMenuSaidas from './pages/Estoques/Saidas/menu'



function App() {
  return (

    <GlobalProvider>

      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<TelaLogin />} />

          <Route exact path="/primeiro-acesso" element={<TelaPrimeiroAcesso />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/cadastro-produtos" element={<CadastroProdutos />} />
          <Route exact path="/cadastro-produtos/inclusao" element={<InclusaoProduto />} />

          <Route exact path="/estoques/entradas" element={<EstoqueMenuEntradas />} />
          <Route exact path="/estoques/entradas/manual" element={<EstoqueMenuEntradasManual />} />

          <Route exact path="/estoques/saidas" element={<EstoqueMenuSaidas />} />

          <Route exact path="/cadastro-departamentos" element={<CadastroDepartamentos />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/integracoes" element={<Integracoes />} />
          <Route exact path="/propostas-comerciais" element={<TelaPropostas />} />
          <Route exact path="/alertas" element={<Alertas />} />
          <Route exact path="/membros" element={<TelaMembros />} />
          <Route exact path="/configuracoes" element={<Configuracoes />} />
          <Route exact path="/minha-empresa/minhas-lojas" element={<MinhasLojas />} />
          <Route exact path="/ajuda" element={<Ajuda />} />
        </Routes>

      </BrowserRouter>

    </GlobalProvider>
  );
}

export default App;

import React, { useState, createContext } from 'react'

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {

    const [conexao] = useState({
        endereco: process.env.REACT_APP_ENDERECO_CONEXAO
    })

    function apenasNumeros(text) {
        var numbers = text.replace(/[^0-9]/g, '');
        return parseInt(numbers);
    }

    function limpaCNPJ(cnpj) {
        if (cnpj) {
            let passo1 = cnpj.replace('/', '');
            let passo2 = passo1.replace('-', '');
            let passo3 = passo2.replace('.', '');
            let passo4 = passo3.replace('.', '');
            let passo5 = passo4.replace('.', '');

            return passo5;
        }

    }

    const [listaTimes, setListaTimes] = useState([])
    const [timeSelecionado, setTimeSelecionado] = useState({})
    const [periodo, setPeriodo] = useState()
    const [modalPeriodo, setModalPeriodo] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
        <GlobalContext.Provider value={{
            conexao,
            listaTimes,
            setListaTimes,
            timeSelecionado,
            setTimeSelecionado,
            periodo,
            setPeriodo,
            modalPeriodo,
            setModalPeriodo,
            loading,
            setLoading,
            apenasNumeros,
            limpaCNPJ
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;
import React from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import { Col, Row, Card } from 'react-bootstrap'

function Analytics() {

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>

                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Analytics
import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { useNavigate } from 'react-router-dom'

import DataTable from "react-data-table-component";

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb } from 'react-bootstrap'

function CadastroProdutos() {

    const { conexao, timeSelecionado, loading, setLoading } = useContext(GlobalContext)

    const [produtos, setProdutos] = useState([])

    const Navigate = useNavigate()

    useEffect(() => {
        GetProdutos()
    }, [])

    function GetProdutos() {
        setLoading(true)

        const url = conexao.endereco + '/produtos/listar'

        let body = {
            "empresa": localStorage.getItem("Empresa")
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setProdutos(resposta)
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.prod_codigo,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '120px',
        },
        {
            name: 'Descrição',
            selector: row => row.prod_descricao,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'EAN',
            selector: row => row.prod_codbarras,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '230px',
        },
        {
            name: 'Departamento',
            selector: row => row.prod_departamento,
            sortable: true,
            // minWidth: '180px',
            maxWidth: '320px',
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Cadastros</Breadcrumb.Item>
                                <Breadcrumb.Item active>Produtos</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        Navigate('/cadastro-produtos/inclusao')
                                    }}>Incluir</Button>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white' }} onClick={() => {
                                        Navigate('/cadastro-produtos/inclusao')
                                    }}>Pesquisar</Button>
                                </ButtonGroup>
                            </Col>
                            <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white' }} onClick={() => {
                                        Navigate('/cadastro-produtos/inclusao')
                                    }}>Classificação de Produtos</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    {/* <Card.Header className="dashboard-cartao"><Row><Col lg={8} style={{display: 'flex', alignItems: 'center'}}>Cadastro de Produtos</Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Descrição" /></Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Departamento" /></Col></Row></Card.Header> */}
                                    <Card.Header className="dashboard-cartao">Cadastro de Produtos</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={produtos}
                                            // onRowClicked={(row, index) => {
                                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                                            // }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            // pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CadastroProdutos
import React, { useState } from "react";
import Sidebar from "../../../components/sidebar";
import TopBar from "../../../components/topbar";

import { Col, Row, Dropdown, Breadcrumb, Form } from 'react-bootstrap'

import Select from 'react-select'

function EstoqueMenuEntradasManual() {

    const operacoes = [
        { value: 'compra_mercadoria', label: 'Compra de Mercadorias' },
        { value: 'compra_uso_consumo', label: 'Compra de Uso Interno' },
        { value: 'compra_materia_prima', label: 'Compra de Materia Prima' },
        { value: 'bonificacao', label: 'Bonificação' },
        { value: 'outras', label: 'Outras Entradas' }
    ]

    const [operacao, setOperacao] = useState('')

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Estoques</Breadcrumb.Item>
                                <Breadcrumb.Item active>Entradas</Breadcrumb.Item>
                                <Breadcrumb.Item active>Lançamento Manual</Breadcrumb.Item>
                            </Breadcrumb>

                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    options={operacoes}
                                    isSearchable
                                    placeholder="Selecione o Tipo de Operação"
                                    closeMenuOnSelect
                                    isClearable
                                    onInputChange={(e) => { setOperacao(e) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {operacao}
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default EstoqueMenuEntradasManual
import React from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import { Col, Row, Card, Form, Button } from 'react-bootstrap'

function Configuracoes() {

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Integração com ERP</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Horário Máximo Integração</Form.Label>
                                                    <Form.Control size="sm" type="time" />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>E-mail Notificação</Form.Label>
                                                    <Form.Control size="sm" type="email" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Notificações do Plano de Ação</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Primeiro Horário</Form.Label>
                                                    <Form.Control size="sm" type="time" />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Segundo Horário</Form.Label>
                                                    <Form.Control size="sm" type="time" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Aviso de Vencimento</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Dias antes do Vencimento</Form.Label>
                                                    <Form.Control size="sm" type="number" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 15 }}>
                            <Button
                                variant="dark"
                                style={{ marginRight: 15, width: 200 }}
                                onClick={() => {
                                    alert('Salvar')
                                }}
                            >Salvar Alterações
                            </Button>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Configuracoes
import React, { useContext, useEffect } from "react";
import { Button, Dropdown, Row, Col, Modal } from 'react-bootstrap'
import { GlobalContext } from "../contexts/global";
import { MdCalendarToday, MdMenu } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function TopBar() {

    const Navigate = useNavigate()

    const { conexao, loading, setLoading } = useContext(GlobalContext)

    return (
        <div className='topbar'>
            <div style={{ paddingLeft: 15 }}>
                <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                    <div style={{ width: '50vw' }}>
                        Olá, {localStorage.getItem('Usuário Logado')}
                    </div>
                    <div style={{ width: '30vw', display: 'flex', justifyContent: 'flex-end' }}>

                        <div className="seletor-periodo-desktop" style={{ marginRight: 15 }}>

                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    Minha Empresa
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { alert('Configurações') }}>Configurações</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { Navigate("/minha-empresa/minhas-lojas") }}>Minhas Lojas</Dropdown.Item>

                                </Dropdown.Menu>

                            </Dropdown>
                            {/* <Button
                                variant="light"
                                style={{ marginLeft: 15 }}
                                onClick={() => {
                                    // Navigate('/')
                                }}
                            >Minha Empresa
                            </Button> */}
                        </div>

                        <Button
                            variant="light"
                            style={{ marginLeft: 15, minWidth: 120 }}
                            onClick={() => {
                                Navigate('/')
                            }}
                        >Sair
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            Processando
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default TopBar
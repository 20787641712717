import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import DataTable from "react-data-table-component";
import { MdSettings } from 'react-icons/md'

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Modal, Form, Button } from 'react-bootstrap'

function CadastroDepartamentos() {

    const { conexao, timeSelecionado, setLoading } = useContext(GlobalContext)

    const [departamentos, setDepartamentos] = useState([])

    const [modalCfgDpto, setModalCfgDpto] = useState(false)

    const [dptoSelecionado, setDptoSelecionado] = useState({})

    useEffect(() => {
        GetDepartamentos()
    }, [timeSelecionado])

    function GetDepartamentos() {

        setLoading(true)

        const url = conexao.endereco + '/departamentos/listar'

        let body = {
            "time": timeSelecionado.id,
            "usuario": ""
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setDepartamentos(resposta)
                setLoading(false)
            }
        }
    }

    function AtualizarDepartamento(dados) {

        setLoading(true)

        const url = conexao.endereco + '/departamentos/atualizar'

        let body = {
            "time": dados.time,
            "codigo": dados.codigo,
            "dias_alerta": dados.dias_alerta
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {

            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetDepartamentos()
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.dpto_codigo,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '120px',
        },
        {
            name: 'Descrição',
            selector: row => row.dpto_descricao,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Dias Alerta',
            selector: row => row.dpto_dias_cor,
            sortable: true,
            maxWidth: '120px',
            center: true
        },
        {
            name: 'Configurar',
            button: true,
            center: true,
            cell: row => (
                <>

                    < a onClick={() => {
                        setDptoSelecionado({
                            ...dptoSelecionado,
                            nome: row.dpto_descricao,
                            codigo: row.dpto_codigo,
                            time: timeSelecionado.id,
                            usuario: localStorage.getItem('email'),
                            dias_alerta: row.dpto_dias_cor
                        })
                        setModalCfgDpto(true)
                    }} className="icone-datagrid">
                        <MdSettings size="24" title="Configurar Departamento" />
                    </a>

                </>

            ),
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Cadastro de Departamentos</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={departamentos}
                                            // onRowClicked={(row, index) => {
                                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                                            // }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            // pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>


            <Modal
                show={modalCfgDpto}
                onHide={() => { setModalCfgDpto(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{dptoSelecionado?.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Número de Dias de Alerta</Form.Label>
                                <Form.Control size="sm" type="number" onChange={(e) => { setDptoSelecionado({ ...dptoSelecionado, dias_alerta: e.target.value }) }} value={dptoSelecionado.dias_alerta} />
                                <Form.Text className="text-muted">
                                    Departamento Será Exibido em Vermelho no Aplicativo
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalCfgDpto(false) }}>
                        Fechar
                    </Button>
                    <Button variant="dark" onClick={() => {


                        setModalCfgDpto(false)
                        AtualizarDepartamento(dptoSelecionado)

                    }}>Aplicar</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default CadastroDepartamentos
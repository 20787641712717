import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import DataTable from "react-data-table-component";

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb, Modal } from 'react-bootstrap'

import { formatToCNPJ, isCNPJ } from 'brazilian-values'

function MinhasLojas() {

    const { conexao, loading, limpaCNPJ, setLoading } = useContext(GlobalContext)

    const [lojas, setLojas] = useState([])
    const [lojaSelecionada, setLojaSelecionada] = useState({})


    const [modalLojas, setModalLojas] = useState(false)
    const [modoEdicao, setModoEdicao] = useState(false)

    const Navigate = useNavigate()

    useEffect(() => {
        GetLojas()
    }, [])

    function GetLojas() {

        setLoading(true)

        const url = conexao.endereco + '/lojas/listar'

        let body = {
            "empresa": localStorage.getItem("Empresa")
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setLojas(resposta)
                setLoading(false)
            }
        }
    }

    function AtualizarLoja() {

        setLoading(true)

        const url = conexao.endereco + '/lojas/atualizar'

        let body = {
            "loja": lojaSelecionada.loja_id,
            "regime": lojaSelecionada.loja_regime,
            "cnpj": limpaCNPJ(lojaSelecionada.loja_cnpj),
            "nome": lojaSelecionada.loja_nome,
            "razao": lojaSelecionada.loja_razao,
            "perc_simples": lojaSelecionada.loja_perc_simples,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                GetLojas()
                setLoading(false)
            }
        }
    }

    function IncluirLoja() {

        setLoading(true)

        const url = conexao.endereco + '/lojas/incluir'

        let body = {
            "empresa": localStorage.getItem("Empresa"),
            "sigla": lojaSelecionada.loja_sigla,
            "regime": lojaSelecionada.loja_regime,
            "cnpj": limpaCNPJ(lojaSelecionada.loja_cnpj),
            "nome": lojaSelecionada.loja_nome,
            "razao": lojaSelecionada.loja_razao,
            "perc_simples": lojaSelecionada.loja_perc_simples,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 201) {
                let resposta = JSON.parse(this.response)
                GetLojas()
                setLoading(false)
            }
        }
    }

    function ExcluirLoja() {

        setLoading(true)

        const url = conexao.endereco + '/lojas/excluir'

        let body = {
            "loja": lojaSelecionada.loja_id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                GetLojas()
                setLoading(false)
            }
        }
    }

    function GetDadosCNPJ(cnpj) {

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const cnpjnumeros = String(limpaCNPJ(cnpj))

        if (isCNPJ(cnpj)) {
            axios.get('https://publica.cnpj.ws/cnpj/' + cnpjnumeros, config)
                .then(function (response) {
                    if (response.status == 200) {
                        const dados = response.data

                        setLojaSelecionada({ ...lojaSelecionada, loja_razao: dados.razao_social, loja_nome: dados.estabelecimento.nome_fantasia })

                    }
                })
                .catch(function (error) {
                    console.log('Deu Erro')
                    console.log(error)
                });
        }



    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Sigla',
            selector: row => row.loja_sigla,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '120px',
        },
        {
            name: 'Nome',
            selector: row => row.loja_nome,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Razão Social',
            selector: row => row.loja_razao,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '380px',
        },
        {
            name: 'CNPJ',
            selector: row => formatToCNPJ(row.loja_cnpj),
            sortable: true,
            // minWidth: '180px',
            maxWidth: '320px',
        },
        {
            name: 'Regime Trib',
            selector: row => row.loja_regime,
            sortable: true,
            // minWidth: '180px',
            maxWidth: '180px',
        },
        {
            name: '% Simples',
            selector: row => row.loja_perc_simples,
            sortable: true,
            center: true,
            maxWidth: '180px',
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Minha Empresa</Breadcrumb.Item>
                                <Breadcrumb.Item active>Minhas Lojas</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="light" style={{ minWidth: 120, backgroundColor: '#436996', color: 'white', marginRight: 0 }} onClick={() => {
                                        setModoEdicao(false)
                                        setLojaSelecionada({})
                                        setModalLojas(true)
                                    }}>Incluir</Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    {/* <Card.Header className="dashboard-cartao"><Row><Col lg={8} style={{display: 'flex', alignItems: 'center'}}>Cadastro de Produtos</Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Descrição" /></Col><Col lg={2}><Form.Control size="sm" type="text" placeholder="Filtrar por Departamento" /></Col></Row></Card.Header> */}
                                    <Card.Header className="dashboard-cartao">Cadastro de Lojas</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={lojas}
                                            onRowClicked={(row, index) => {
                                                setModoEdicao(true)
                                                setLojaSelecionada(row)
                                                setModalLojas(true)
                                            }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>

            <Modal
                show={modalLojas}
                onHide={() => { setModalLojas(false) }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{lojaSelecionada?.loja_nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Sigla</Form.Label>
                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_sigla: e.target.value }) }} value={lojaSelecionada?.loja_sigla} disabled={modoEdicao} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Regime Tributário</Form.Label>
                                <Form.Select size="sm" onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_regime: e.target.value }) }} value={lojaSelecionada?.loja_regime}>
                                    <option>Selecione</option>
                                    <option value="SIMPLES">Simples Nacional</option>
                                    {/* <option value="REAL">Lucro Real</option>
                                    <option value="PRESUMIDO">Lucro Presumido</option> */}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>CNPJ</Form.Label>
                                <Form.Control size="sm" type="text" onBlur={() => { GetDadosCNPJ(lojaSelecionada?.loja_cnpj) }} onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_cnpj: e.target.value }) }} value={lojaSelecionada?.loja_cnpj ? formatToCNPJ(lojaSelecionada?.loja_cnpj) : lojaSelecionada?.loja_cnpj} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Nome Fantasia</Form.Label>
                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_nome: e.target.value }) }} value={lojaSelecionada?.loja_nome} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Razão Social</Form.Label>
                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_razao: e.target.value }) }} value={lojaSelecionada?.loja_razao} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Percentual Simples Nacional</Form.Label>
                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="number" onChange={(e) => { setLojaSelecionada({ ...lojaSelecionada, loja_perc_simples: e.target.value }) }} value={lojaSelecionada?.loja_perc_simples} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light"
                        onClick={() => { setModalLojas(false) }}
                    >
                        Fechar
                    </Button>
                    {modoEdicao &&
                        <Button variant="danger" onClick={() => {

                            ExcluirLoja()
                            setModalLojas(false)

                        }}>Excluir</Button>
                    }
                    {modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            // alert(limpaCNPJ(lojaSelecionada.loja_cnpj))
                            AtualizarLoja()
                            setModalLojas(false)

                        }}>Salvar</Button>
                    }
                    {!modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            // alert(limpaCNPJ(lojaSelecionada.loja_cnpj))
                            IncluirLoja()
                            setModalLojas(false)

                        }}>Cadastrar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MinhasLojas
import React, { useState } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import { Col, Row, Card, Breadcrumb, Form, Tabs, Tab } from 'react-bootstrap'

import Select from 'react-select'
import { useNavigate } from "react-router-dom";


function InclusaoProduto() {

    const Navigate = useNavigate()

    const [produtoSelecionado, setProdutoSelecionado] = useState()

    const classificacao = [
        { value: 'mercearia', label: 'Mercearia' },
        { value: 'bebidas', label: 'Bebidas' },
        { value: 'higiene_limpeza', label: 'Higiene e Limpeza' }
    ]

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item active>Cadastros</Breadcrumb.Item>
                                <Breadcrumb.Item active>Produtos</Breadcrumb.Item>
                                <Breadcrumb.Item active>Inclusão</Breadcrumb.Item>
                            </Breadcrumb>

                        </Row>
                        <Row>
                            <Tabs
                                defaultActiveKey="basico"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="basico" title="Cadastro Básico">
                                    <Row>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Código de Barras</Form.Label>
                                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, barras: e.target.value }) }} value={produtoSelecionado?.barras} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Descrição</Form.Label>
                                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, descricao: e.target.value }) }} value={produtoSelecionado?.descricao} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Marca</Form.Label>
                                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, marca: e.target.value }) }} value={produtoSelecionado?.marca} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Departamento</Form.Label>
                                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, departamento: e.target.value }) }} value={produtoSelecionado?.departamento} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Grupo</Form.Label>
                                                <Form.Control style={{ textTransform: "capitalize" }} size="sm" type="text" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, grupo: e.target.value }) }} value={produtoSelecionado?.grupo} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Un Medida</Form.Label>
                                                <Form.Select size="sm" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, unmedida: e.target.value }) }} value={produtoSelecionado?.unmedida}>
                                                    <option>Selecione</option>
                                                    <option value="UN">Unidade</option>
                                                    <option value="CX">Caixa</option>
                                                    <option value="KG">Quilo</option>
                                                    <option value="FD">Fardo</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Fracionado</Form.Label>
                                                <Form.Select size="sm" onChange={(e) => { setProdutoSelecionado({ ...produtoSelecionado, fracionado: e.target.value }) }} value={produtoSelecionado?.fracionado}>
                                                    <option>Selecione</option>
                                                    <option value="S">Sim</option>
                                                    <option value="N">Não</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="precificacao" title="Precificação">
                                    <h1>Tab 2</h1>
                                </Tab>
                                <Tab eventKey="comercial" title="Comercial">
                                    <h1>Tab 3</h1>
                                </Tab>
                                <Tab eventKey="recebimento" title="Recebimento">
                                    <h1>Tab 3</h1>
                                </Tab>
                                <Tab eventKey="automacao" title="Automação">
                                    <h1>Tab 3</h1>
                                </Tab>
                                <Tab eventKey="opcoes" title="Opções">
                                    <h1>Tab 3</h1>
                                </Tab>
                            </Tabs>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default InclusaoProduto